import React from 'react'
import Slider from 'react-slick'
import { withTranslation } from 'react-i18next'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Gallery from '../components/gallery'

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 400,
  centerMode: true,
  centerPadding: '180px',
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '90px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: false
      }
    }
  ]
}

const IndexPage = ({ t }) => {
  return (
    <Layout>
      <SEO title={t('header.home')} keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
      <section className="message">
        <div className="container grid-xl">
          <div className="message-container clearfix">
            <div className="message-img" style={{
              backgroundImage: `url(${require('../images/sample_5.jpg')})`
            }}></div>
            <div className="message-content">
              <p>{t('message.sentence1')}</p>
              <p>{t('message.sentence2')}</p>
              <p>{t('message.sentence3')}</p>
              <p>{t('message.sentence4')}</p>
              <p>{t('message.sentence5')}</p>
              <p>{t('message.sentence6')}</p>
              {t('message.sentence7')}
            </div>
          </div>

          <div className="fund">
            <h2 className="title">{ t('fund.title') }</h2>
            <div className="container grid-xl">
              <div className="columns">
                <div className="column col-md-12 col-5 mb-2">
                  <img src={require('../images/fund.jpg')} className="img-responsive"/>
                </div>
                <div className="column col-md-12 col-7">
                  <h3 className="subtitle">{ t('fund.subtitle') }</h3>
                  <ol className="list">
                    <li>{t('fund.line1')}</li>
                    <li>{t('fund.line2')}</li>
                    <li>{t('fund.line3')}</li>
                  </ol>
                  <p dangerouslySetInnerHTML={{ __html: t('fund.see_more') }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withTranslation()(IndexPage)
